<template>
    <transition>
        <component :is="current" @switch="switchPage" ref="page"></component>
    </transition>
</template>

<script>
    import Detail from "./detail.vue";
    import List from "./list.vue";
    import ViewRow from "./viewRow.vue";
    export default {
        data() {
            return {
                current: "List",

                option: null,

                wasteTypes: [],
                packWayList: [],
                industryList: [],
                countList: [],
                unitList: []
            };
        },
        created() {
            this.initCountList();
            let p2 = this.$http.post("/wasteType/listAll"),
                p3 = this.$http.post("/dict/getDictValuesByDictId", "dictpackway"),
                p4 = this.$http.post("/dict/getDictValuesByDictId", "dictindustry"),
                p5 = this.$http.post("/unitInfo/queryUnitList", {
                    cName: "",
                    unitType: "0"
                });
            Promise.all([p2, p3, p4, p5])
                .then(res => {
                    const [r2, r3, r4, r5] = res;
                    this.wasteTypes = r2.detail;
                    this.packWayList = r3.detail;
                    this.industryList = r4.detail;
                    this.unitList = r5.detail;
                })
                .then(() => {
                    this.$refs.page.getList && this.$refs.page.getList();
                });
        },
        methods: {
            switchPage(name, option) {
                // if (this.current === "List") {
                //     this.current = "Detail";
                // } else {
                //     this.current = "List";
                // }
                this.current = name;
                this.option = option;
            },
            initCountList() {
                this.$http.post("/wasteOrder/countList").then(res => {
                    this.countList = res.detail;
                });
            }
        },
        components: {
            Detail,
            List,
            ViewRow
        }
    };
</script>
<style></style>
