<template>
    <div>
        <el-page-header @back="backMain" content="委托订单 > 查看"></el-page-header>
        <el-form :model="model" class="detail_form" size="small" label-position="right" label-width="130px">
            <div class="form_item">
                <div class="head_title">基本信息</div>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="委托单号" prop="wOrderId">{{ model.wOrderId }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="产废企业">{{ model.unitId }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="状态">{{ model.statusName }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="抛/重物">{{ model.nature === "1" ? "抛物" : "重物" }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="包装方式">{{ model.pack }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="所属行业">{{ model.industry }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="企业产废类别">{{ model.wasteTNa }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="企业产废废物">{{ model.wasteNa }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="发起人">{{ model.cUserNa || model.unitId }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="发起时间">{{ model.createT }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="备注">{{ model.remark }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="企业废固照片">
                            <com-img :url="model.picUrl ||PicImg"></com-img>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="form_item">
                <div class="head_title">地址信息</div>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="产废设施地址">{{ model.addr }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="联系人">{{ model.contact }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="联系人职位">{{ model.conPos }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="联系电话">{{ model.conPhone }}</el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="form_item">
                <div class="head_title">拟固废清运明细</div>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="清运固废类别">{{ model.wasteTStr }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="拟清运重量">{{ model.weight }}kg</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="期望到达时间">{{ model.expireT }} {{ model.amOrPm == "1" ? "下午" : "上午" }}</el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="form_item">
                <div class="head_title">调度单信息</div>
                <el-form-item label="清运总重量">{{ model.wasteTNa }}{{ model.weightTotal }}kg</el-form-item>
                <el-table :data="orderList">
                    <el-table-column type="expand">
                        <template v-slot="props">
                            <el-form>
                                <el-row>
                                    <el-col :span="8">
                                        <el-form-item label="实际净重">
                                            {{ formatDUnit(props.row.unitId) }}{{ formatWeight(props.row.grossW, props.row.tareW) }}kg
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="毛重">{{ props.row.grossW }}kg</el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="皮重">{{ props.row.tareW }}kg</el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="8">
                                        <el-form-item label="实际装运时间">{{ model.grossWT }}</el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="毛重过磅时间">{{ props.row.grossWT }}</el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="皮重过磅时间">{{ props.row.tareWT }}</el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="8">
                                        <el-form-item label="装运照片">
                                            <com-img :url="model.grossWUrl"></com-img>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="毛重过磅照片">
                                            <com-img :url="props.row.grossWUrl"></com-img>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="皮重过磅照片">
                                            <com-img :url="props.row.tareWUrl"></com-img>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </template>
                    </el-table-column>
                    <el-table-column label="调度单号" prop="scheduleId"></el-table-column>
                    <el-table-column label="分拣中心名称" prop="unitId">
                        <template v-slot="scope">{{ formatDUnit(scope.row.unitId) }}</template>
                    </el-table-column>
                    <el-table-column label="车牌号">
                        <template v-slot="scope">{{ scope.row.vehicleInfo.plateNum }}</template>
                    </el-table-column>
                    <el-table-column label="司机">
                        <template v-slot="scope">{{ scope.row.driverList | formatDrivers }}</template>
                    </el-table-column>
                    <el-table-column label="调度发车日期时间" prop="pTime"></el-table-column>
                    <el-table-column label="子委托订单状态">
                        <!-- <template v-slot="scope">{{ formatStatus(scope.row.status) }}</template> -->
                        <template v-slot="scope">{{ model.statusName }}</template>
                    </el-table-column>
                    <el-table-column label="实际发车时间" prop="rTime"></el-table-column>
                    <el-table-column label="操作">
                        <template v-slot="scope">
                            <el-button type="text" @click="navToDispatch(scope.row.scheduleId)">查看调度单</el-button>
                            <el-button v-if="false" type="text" @click="showTrack">运输轨迹</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="form_item" v-if="model.status == '1'">
                <div class="head_title">拒绝信息</div>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="拒绝原因">{{ model.rejectRS }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="拒绝人">{{model.rejectUNa || model.rejectUId }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="拒绝时间">{{ model.rejectT }}</el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="form_item" v-if="model.status == '4'">
                <div class="head_title">取消信息</div>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="取消原因">{{ model.cancelRS }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="取消人">{{ model.cancelUNa || model.cancelUId }}</el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="取消时间">{{ model.cancelT }}</el-form-item>
                    </el-col>
                </el-row>
            </div>
        </el-form>

        <transport-track ref="track"></transport-track>
    </div>
</template>

<script>
    import TransportTrack from "@/components/pages/admin/map/TransportTrack.vue";
    import PicImg from "@/assets/common/default.png";
    export default {
        created() {
            this.getDicts();
        },
        methods: {
            formatWeight(r1, r2) {
                if (isNaN(r1) || isNaN(r2)) {
                    return "-";
                }
                return r1 - r2;
            },
            navToDispatch(scheduleId) {
                this.$router.push({
                    name: "dOrder",
                    params: {
                        scheduleId
                    }
                });
            },
            getDicts() {
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "3"
                    })
                    .then(res => {
                        this.dUnitList = res.detail;
                    });
            },
            backMain() {
                this.$emit("switch", "List");
            },
            showTrack() {
                this.$refs.track.init();
            },
            formatStatus(val) {
                const s = this.radioList.find(v => v.status === val);
                return s.statusStr;
            },
            formatDUnit(val) {
                const u = this.dUnitList.find(v => v.unitId === val);
                return u ? u.cname : "-";
            },
            formatUnit(val) {
                const u = this.unitList.find(v => v.unitId === val);
                return u ? u.cname : "-";
            },
            getScheduleDetail(scheduleId) {
                if (scheduleId) {
                    this.$http.post("/schedule/getDetailByScheduleId", scheduleId).then(res => {
                        this.orderList = [res.detail];
                    });
                }
            },
            getWasteOrderDetail(wOrderId) {
                this.$http.post("/wasteOrder/detail", wOrderId).then(res => {
                    const { scheduleOrder, unitInfo ,wasteOrder} = res.detail;
                    let weightTotal = 0;
                    if (scheduleOrder && scheduleOrder.weightTotal) {
                        weightTotal = scheduleOrder.weightTotal;
                    }
                    if (wasteOrder) {
                        this.$set(this.model,"cancelUNa",wasteOrder.cancelUNa)
                        this.$set(this.model,"rejectUNa",wasteOrder.rejectUNa)
                    }
                    this.$set(this.model, "weightTotal", weightTotal);
                    this.$set(this.model, "industry", unitInfo.industry);
                    this.$set(this.model, "wasteTNa", unitInfo.wasteTNa);
                    this.$set(this.model, "wasteNa", unitInfo.wasteNa);
                });
            }
        },
        filters: {
            formatDrivers(list) {
                if (list && list.length) {
                    return list.map(v => v.cName).join(",");
                } else {
                    return "-";
                }
            }
        },
        computed: {
            radioList() {
                return this.$parent.countList;
            },
            unitList() {
                return this.$parent.unitList;
            }
        },
        mounted() {
            this.model = JSON.parse(JSON.stringify(this.$parent.option));
            this.model.statusName = this.formatStatus(this.model.status);
            this.model.unitId = this.formatUnit(this.model.unitId);
            this.getScheduleDetail(this.model.scheduleId);
            this.getWasteOrderDetail(this.model.wOrderId);
        },
        data() {
            return {
                model: {},
                dataList: [],
                orderList: [],

                PicImg
            };
        },
        components: { TransportTrack }
    };
</script>
<style lang="scss" scoped>
    .avatar {
        width: 100px;
        height: 100px;
    }
    .detail_form {
        margin-top: 20px;
        .form_item {
            margin-bottom: 30px;
            .head_title {
                font-size: 18px;
            }
            .head_title::before {
                content: "";
                display: inline-block;
                width: 7px;
                height: 26px;
                margin-right: 5px;
                vertical-align: middle;
                background-color: #3498db;
            }
        }
    }

    .form_handle {
        text-align: center;
    }
</style>
