<template>
    <div>
        <el-radio-group v-model="status" size="mini" @change="orderChange">
            <el-radio-button v-for="r in radioList" :key="r.status" :label="r.status">{{ r.statusStr }}（{{ r.count }}）</el-radio-button>
        </el-radio-group>

        <jy-query ref="form" :model="model" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="委托单号" prop="wOrderId">
                <el-input v-model="model.wOrderId"></el-input>
            </jy-query-item>
            <jy-query-item label="调度单号" prop="scheduleId" v-if="showScheduleQuery">
                <el-input v-model="model.scheduleId"></el-input>
            </jy-query-item>
            <jy-query-item label="产废企业" prop="unitId">
                <el-select v-model="model.unitId">
                    <el-option v-for="u in unitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="固废类别" prop="wasteTId">
                <el-select v-model="model.wasteTId">
                    <el-option v-for="t in wasteTypes" :key="t.wasteTId" :label="t.cName" :value="t.wasteTId"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="抛/重物" prop="nature">
                <el-select v-model="model.nature">
                    <el-option label="抛物" value="1"></el-option>
                    <el-option label="重物" value="0"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="包装方式" prop="pack">
                <el-select v-model="model.pack">
                    <el-option v-for="p in packWayList" :key="p.iValue" :value="p.iValue" :label="p.iValue"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="所属行业" prop="industry">
                <el-select v-model="model.industry">
                    <el-option v-for="p in industryList" :key="p.iValue" :value="p.iValue" :label="p.iValue"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="企业产废类别" prop="wasteT">
                <el-select v-model="model.wasteT">
                    <el-option v-for="t in wasteTypes" :key="t.wasteTId" :label="t.cName" :value="t.wasteTId"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="企业产废废物" prop="wasteNa">
                <el-input v-model="model.wasteNa"></el-input>
            </jy-query-item>
            <jy-query-item label="发起人" prop="cUserNa">
                <el-input v-model="model.cUserNa"></el-input>
            </jy-query-item>
            <jy-query-item label="发起日期" prop="createT">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="model.createT"></el-date-picker>
            </jy-query-item>
            <jy-query-item label="期望日期" prop="expireT">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="model.expireT"></el-date-picker>
            </jy-query-item>
            <jy-query-item label="所属机构" prop="orgId">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="model.orgNa"></el-input>
                </div>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck" v-if="btnexist('wastesSystemOrderList')">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')" v-if="btnexist('wastesSystemOrderReset')">重置</el-button>
                <el-row>
                    <el-button size="small" type="primary" v-on:click="add" v-if="btnexist('wastesSystemOrderInsert')">新增</el-button>
                    <export-excel
                        v-if="btnexist('wastesSystemOrderExport') && false"
                        baseUrl="/iwastes-admin"
                        url="/wasteOrder/export"
                        :params="model"
                        fileName="委托订单"
                        fileType=".xls"
                        size="small"
                    ></export-excel>
                    <!-- <el-button size="small" type="primary" @click="showDefineTable">自定义列表</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <div class="jy-table" v-loading="loading">
            <c-table ref="ctable" :total="total" @update="getList" :columns="rColumns" :data="dataList" isGenerate @cell-click="handleClick"></c-table>
        </div>

        <t-fixed ref="tFixed" :banList="['unitId']"></t-fixed>
        <reject ref="reject" @update="update"></reject>
        <institutions-tree :showType="[1]" ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
    </div>
</template>

<script>
    import CTable from "@/components/pages/admin/common/ctable.vue";
    import TFixed from "@/components/pages/admin/common/TFixed/tFixed.vue";
    import ExportExcel from "@/components/pages/admin/common/export";
    import Reject from "@/components/pages/admin/order/Reject.vue";
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";

    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        mounted() {
            this.$nextTick(() => {
                if (this.$route.params.wOrderId) {
                    this.model.wOrderId = this.$route.params.wOrderId;
                    this.status = "";
                }
                if (this.$route.params.scheduleId) {
                    this.model.scheduleId = this.$route.params.scheduleId;
                    this.status = "";
                }
                this.getList();
                const columns = this.$refs.tFixed.showList;
                this.columnProps = columns.length ? columns : this.columns.map(v => v.prop);
            });
        },
        activated() {
            if (this.$route.params.wOrderId) {
                this.model.wOrderId = this.$route.params.wOrderId;
                this.status = "";
                this.getList();
            }
            if (this.$route.params.scheduleId) {
                this.model.scheduleId = this.$route.params.scheduleId;
                this.status = "";
                this.getList();
            }
        },
        mixins: [btnMixins],
        methods: {
            init() {},
            update() {
                this.getList();
                this.updateCountList();
            },
            updateCountList() {
                this.$parent.initCountList();
            },
            setOperateList(row) {
                let l = [];
                if (this.btnexist("wastesSystemOrderView")) {
                    l.push({
                        index: "view",
                        title: "查看"
                    });
                }
                if (this.btnexist("wastesSystemOrderUpdate") && row.status == "0") {
                    l.push({
                        index: "edit",
                        title: "编辑"
                    });
                }
                if (this.btnexist("wastesSystemOrderReject") && row.status == "0") {
                    l.push({
                        index: "reject",
                        title: "拒绝"
                    });
                }
                if (this.btnexist("wastesSystemOrderCancel") && row.status != "4") {
                    l.push({
                        index: "cancel",
                        title: "取消"
                    });
                }
                if (this.btnexist("wastesSystemOrderDelete") && row.status == "4") {
                    l.push({
                        index: "delete",
                        title: "删除"
                    });
                }
                return l;
            },
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                Object.keys(this.model).forEach(key => {
                    this.model[key] = null
                })
                this.oncheck();
            },
            getList() {
                const page = this.$refs.ctable.getPager();
                let url = "/wasteOrder/pageQuery";
                let params = {
                    ...this.model,
                    status: this.status,
                    pageIndex: page.pageIndex,
                    pageSize: page.pageSize,
                    createBeginTStr: this.$util.addTime(this.model.createT && this.model.createT[0], true),
                    createEndTStr: this.$util.addTime(this.model.createT && this.model.createT[1]),
                    expireBeginT: this.$util.addTime(this.model.expireT && this.model.expireT[0], true),
                    expireEndT: this.$util.addTime(this.model.expireT && this.model.expireT[1]),
                    dType: "0",
                    createT: undefined,
                    expireT: undefined
                };
                this.loading = true;
                this.$http
                    .post(url, params, { isRequestParam: false })
                    .then(({ detail }) => {
                        this.dataList = detail.list;
                        this.total = detail.total;
                        this.dataList.forEach(v => {
                            v.btnList = this.setOperateList(v);
                        });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            add() {
                this.$emit("switch", "Detail");
            },
            showDefineTable() {
                this.$refs.tFixed.init(this.columns);
            },
            orderChange() {
                if (this.status === "5") {
                    this.columns[3].unShow = true;
                    this.columns[4].unShow = false;

                    this.columns[9].unShow = true;
                } else {
                    this.columns[3].unShow = false;
                    this.columns[4].unShow = true;

                    this.columns[9].unShow = false;
                }

                if (this.status === "4") {
                    this.columns[11].unShow = false;
                } else {
                    this.columns[11].unShow = true;
                }

                this.getList();
            },
            handleClick(rowIndex, key, row) {
                if (key === "delete") {
                    this.handleDelete(row);
                } else if (key === "reject") {
                    this.$refs.reject.init(row, true);
                } else if (key === "cancel") {
                    this.$refs.reject.init(row, false);
                } else if (key === "view") {
                    this.$emit("switch", "ViewRow", row);
                } else if (key === "edit") {
                    this.$emit("switch", "Detail", row);
                } else if (key === "nav") {
                    this.$router.push({
                        name: "dOrder",
                        params: {
                            scheduleId: row.scheduleId
                        }
                    });
                }
            },
            handleDelete(row) {
                this.$confirm("是否删除该委托订单，删除不可恢复?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        let option = row.wOrderId;
                        let url = "/wasteOrder/delete";
                        this.$http.post(url, option).then(res => {
                            this.$message({
                                type: "success",
                                message: res.msg
                            });
                            this.getList();
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            addInstitutions(data) {
                this.model.orgId = data.orgId;
                this.model.orgNa = data.orgNa;
            },
            // 选择机构树
            selectInstitutions() {
                this.$refs.institutions.init();
            },
            formatTWeight(weight, wasteTId) {
                const w = this.wasteTypes.find(v => v.wasteTId === wasteTId);
                if (w) {
                    return weight + w.unit;
                } else {
                    return weight;
                }
            }
        },
        computed: {
            wasteTypes() {
                return this.$parent.wasteTypes;
            },
            packWayList() {
                return this.$parent.packWayList;
            },
            industryList() {
                return this.$parent.industryList;
            },
            radioList() {
                return this.$parent.countList;
            },
            unitList() {
                return this.$parent.unitList;
            },
            showScheduleQuery() {
                return ["", "2", "3", "5"].includes(this.status);
            },
            rColumns() {
                let list = [];
                this.columnProps.forEach(prop => {
                    const itemIndex = this.columns.findIndex(v => v.prop == prop);
                    if (itemIndex != -1) {
                        list.push(this.columns[itemIndex]);
                    }
                });
                return list;
            }
        },
        data() {
            return {
                loading: false,
                model: {
                    wOrderId: "",
                    wasteTId: "",
                    nature: "",
                    pack: "",
                    industry: "",
                    wasteT: "",
                    unitId:"",
                    wasteNa: "",
                    cUserNa: "",
                    orgNa: "",
                    orgId: "",
                    createT: [],
                    expireT: [],
                    scheduleId: ""
                },

                columnProps: [],
                dataList: [],
                operateList: [],
                total: 0,
                columns: [
                    {
                        title: "产废企业",
                        prop: "unitId",
                        formatter: (rowIndex, key, row) => {
                            const u = this.unitList.find(v => v.unitId === row[key]);
                            return u ? u.cname : "-";
                        }
                    },
                    {
                        title: "委托单号",
                        prop: "wOrderId"
                    },
                    {
                        title: "固废类别",
                        prop: "wasteTId",
                        formatter: (rowIndex, key, row) => {
                            const w = this.wasteTypes.find(v => v.wasteTId === row[key]);
                            return w ? w.cName : "-";
                        }
                    },
                    {
                        title: "拟固废清运量",
                        prop: "weight",
                        formatter: (rowIndex, key, row) => {
                            return this.formatTWeight(row[key], row.wasteTId);
                        },
                        unShow: false
                    },
                    {
                        title: "清运总重量",
                        prop: "rWeight",
                        formatter: (rowIndex, key, row) => {
                            return row[key] + "kg";
                        },
                        unShow: true
                    },
                    {
                        title: "期望到达时间",
                        prop: "expireT",
                        formatter: (rowIndex, key, row) => {
                            return row[key] + (row.amOrPm == "1" ? "下午" : "上午");
                        }
                    },
                    {
                        title: "产废设施地址",
                        prop: "addr"
                    },
                    {
                        title: "抛/重物",
                        prop: "nature",
                        formatter: (rowIndex, key, row) => {
                            return row[key] === "1" ? "抛物" : "重物";
                        }
                    },
                    {
                        title: "包装方式",
                        prop: "pack"
                    },
                    {
                        title: "企业固废照片",
                        prop: "picUrl",
                        image: true,
                        unshow: false
                    },
                    {
                        title: "状态",
                        prop: "status",
                        cTip: "待派单：委托订单未发起调度派单<br/>已派单：委托订单已发起调度派单<br/>运输中：委托订单关联的调度单，已发车<br>已完成：委托订单关联的调度单，已完成<br>已拒绝：委托订单被驳回<br>已取消：委托订单取消",
                        formatter: (rowIndex, key, row) => {
                            const s = this.radioList.find(v => v.status === row[key]);
                            return s ? s.statusStr : "-";
                        }
                    },
                    {
                        title: "取消原因",
                        prop: "rejectRS",
                        unShow: true
                    },
                    {
                        title: "联系人",
                        prop: "contact"
                    },
                    {
                        title: "联系人职位",
                        prop: "conPos"
                    },
                    {
                        title: "联系电话",
                        prop: "conPhone"
                    },
                    {
                        title: "所属行业",
                        prop: "industry",
                        formatter: (rowIndex, key, row) => {
                            return row.unitInfo ? row.unitInfo.industry : "-";
                        }
                    },
                    {
                        title: "企业产废类别",
                        prop: "wasteT",
                        formatter: (rowIndex, key, row) => {
                            if (row.unitInfo) {
                                const w = this.wasteTypes.find(v => v.wasteTId === row.unitInfo.wasteT);
                                return w ? w.cName : "-";
                            }
                            return "-";
                        }
                    },
                    {
                        title: "企业产废废物",
                        prop: "wasteNa",
                        formatter: (rowIndex, key, row) => {
                            return row.unitInfo ? row.unitInfo.wasteNa : "-";
                        }
                    },
                    {
                        title: "关联调度单",
                        prop: "scheduleId",
                        clicked: true,
                        index: "nav",
                        formatter: (rowIndex, key, row) => {
                            return row[key];
                        }
                    },
                    {
                        title: "发起人",
                        prop: "cUserNa"
                    },
                    {
                        title: "发起时间",
                        prop: "createT"
                    },
                    {
                        title: "所属机构",
                        prop: "orgNa"
                    },
                    {
                        title: "备注",
                        prop: "remark"
                    }
                ],

                status: "0",

                btnMenuId: "wastesSystemOrder"
            };
        },
        components: {
            CTable,
            TFixed,
            ExportExcel,
            Reject,
            institutionsTree
        }
    };
</script>
<style scoped>
    .query_area {
        margin-top: 15px;
    }
</style>
