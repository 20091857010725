<template>
    <div>
        <el-page-header @back="backMain" :content="'委托订单 > ' + title"></el-page-header>
        <el-form :model="model" ref="orderForm" class="detail_form" size="small" label-position="right" label-width="140px" :rules="rules">
            <div class="form_item">
                <div class="head_title">基本信息</div>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="产废企业" prop="unitId">
                            <el-autocomplete
                                v-model="orgName"
                                :fetch-suggestions="querySearchAsync"
                                placeholder="请输入产废企业名称"
                                value-key="cname"
                                @select="handleSelect"
                            ></el-autocomplete>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="抛/重物" prop="nature">
                            <el-select v-model="model.nature">
                                <el-option label="抛物" value="1"></el-option>
                                <el-option label="重物" value="0"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="包装方式" prop="pack">
                            <el-select v-model="model.pack">
                                <el-option v-for="p in packWayList" :key="p.iValue" :value="p.iValue" :label="p.iValue"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="备注">
                            <el-input v-model="model.remark"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="所属行业">{{ industryName }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="企业产废类别">{{ typeName }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="企业产废废物">{{ rubbishName }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="企业废固照片">
                            <el-upload
                                class="avatar-uploader"
                                :action="uploadImgUrl"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload"
                                :headers="uploadHeader"
                            >
                                <img v-if="model.picUrl" :src="model.picUrl" class="avatar" />
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="form_item">
                <div class="head_title">地址信息</div>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="产废设施地址" prop="addr">
                            <el-select v-model="model.addr" @change="changeLocation">
                                <el-option v-for="l in locationList" :key="l.lng + l.lat" :label="l.addr" :value="l.addr"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="联系人" prop="contact">
                            <el-input v-model="model.contact"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="联系人职位" prop="conPos">
                            <el-input v-model="model.conPos"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="联系电话" prop="conPhone">
                            <el-input v-model="model.conPhone"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="form_item">
                <div class="head_title">拟固废清运明细</div>
                <el-form-item label="选择清运固废类别" prop="wasteTId">
                    <el-radio-group v-model="model.wasteTId">
                        <el-radio-button v-for="t in wasteTypes" :key="t.wasteTId" :label="t.wasteTId">{{ t.cName }}</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="拟清运重量" prop="weight">
                    <el-input v-model="model.weight" class="weight_input">
                        <template slot="append">{{ unitName }}</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="期望到达时间" prop="expireT">
                    <el-date-picker value-format="yyyy-MM-dd" type="date" v-model="model.expireT"></el-date-picker>
                    <el-select v-model="model.amOrPm" class="expire_time_range">
                        <el-option label="上午" value="0"></el-option>
                        <el-option label="下午" value="1"></el-option>
                    </el-select>
                </el-form-item>
            </div>
        </el-form>
        <div class="form_handle">
            <el-button type="primary" size="small" @click="save">确定</el-button>
            <el-button size="small" @click="backMain">取消</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            backMain() {
                this.$emit("switch", "List");
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === "image/jpeg" || file.type === "image/png";

                if (!isJPG) {
                    this.$message.error("图片只能是JPG或PNG格式");
                }
                return isJPG;
            },
            handleAvatarSuccess({ detail }) {
                this.model.picUrl = detail;
            },
            querySearchAsync(queryString, cb) {
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: queryString,
                        unitType: "0"
                    })
                    .then(res => {
                        cb(res.detail);
                    });
            },
            handleSelect(row) {
                this.model.unitId = row.unitId;
                this.industryName = row.industry;
                this.model.contact = row.contact;
                this.model.conPos = row.conPos;
                this.model.conPhone = row.conPhone;
                if (row.wasteT) {
                    this.model.wasteT = row.wasteT;
                    this.typeName = this.formatWasteType(row.wasteT);
                    this.model.wasteTId = row.wasteT;
                }
                this.rubbishName = row.wasteNa;
                this.model.mOrgId = row.mOrgId;

                this.getlocations();
            },
            getlocations() {
                this.$http.post("/unitInfo/getAddrsByUnitId", this.model.unitId).then(res => {
                    this.locationList = res.detail;
                });
            },
            changeLocation(addr) {
                const item = this.locationList.find(each => each.addr === addr)
                this.model.lng = item.lng;
                this.model.lat = item.lat;
                console.log(item)
            },
            save() {
                let url = "/wasteOrder/add";
                if (this.isEdit) {
                    url = "/wasteOrder/update";
                }
                this.$refs.orderForm.validate(valide => {
                    if (valide) {
                        this.$http.post(url, this.model).then(res => {
                            this.$message.success({
                                message: res.msg
                            });
                            this.$emit("switch", "List");
                            this.$parent.initCountList();
                        });
                    }
                });
            },
            formatWasteType(key) {
                const w = this.wasteTypes.find(v => v.wasteTId === key);
                return w ? w.cName : "-";
            }
        },
        mounted() {
            if (this.$parent.option) {
                this.model = this.$parent.option;
                this.orgName = this.$parent.option.unitNa;
                this.typeName = this.formatWasteType(this.model.wasteT);
                this.industryName = this.model.industry;
                this.getlocations();
                this.title = "编辑";
            }
        },
        computed: {
            wasteTypes() {
                return this.$parent.wasteTypes;
            },
            packWayList() {
                return this.$parent.packWayList;
            },
            industryList() {
                return this.$parent.industryList;
            },
            isEdit() {
                return !!this.model.wOrderId;
            },
            unitName() {
                const one = this.wasteTypes.find(v => v.wasteTId === this.model.wasteTId);
                return one ? one.unit : "-";
            }
        },
        data() {
            return {
                title: "新增",
                model: {
                    wOrderId: "",
                    picUrl: "",
                    unitId: "",
                    nature: "",
                    pack: "",
                    remark: "",
                    lng: "",
                    lat: "",
                    addr: "",
                    contact: "",
                    conPos: "",
                    conPhone: "",
                    wasteTId: "",
                    weight: "",
                    expireT: "",
                    amOrPm: "0"
                },

                rules: {
                    unitId: [{ required: true, message: "产废企业不为空", trigger: "change" }],
                    nature: [{ required: true, message: "抛/重物不为空", trigger: "change" }],
                    pack: [{ required: true, message: "包装方式不为空", trigger: "change" }],
                    addr: [{ required: true, message: "产废设施地址不为空", trigger: "change" }],
                    contact: [{ required: true, message: "联系人不为空", trigger: "blur" }],
                    conPos: [{ required: true, message: "联系人职位不为空", trigger: "blur" }],
                    conPhone: [{ required: true, message: "联系人电话不为空", trigger: "blur" }],
                    wasteTId: [{ required: true, message: "清运固废类别不为空", trigger: "change" }],
                    weight: [{ required: true, message: "拟清运重量不为空", trigger: "blur" }],
                    expireT: [{ required: true, message: "期望到达时间不为空", trigger: "change" }]
                },

                orgName: "",
                industryName: "",
                typeName: "",
                rubbishName: "",

                locationList: [],

                uploadImgUrl: "/iwastes-admin/sys/user/uploadLogo",
                uploadHeader: {
                    token: this.$util.getStor("token")
                }
            };
        }
    };
</script>
<style lang="scss" scoped>
    .detail_form {
        margin-top: 20px;
        .form_item {
            margin-bottom: 30px;
            .head_title {
                font-size: 18px;
            }
            .head_title::before {
                content: "";
                display: inline-block;
                width: 7px;
                height: 26px;
                margin-right: 5px;
                vertical-align: middle;
                background-color: #3498db;
            }
        }
    }

    .form_handle {
        text-align: center;
    }

    .expire_time_range {
        margin-left: 8px;
        width: 80px;
    }
    .weight_input {
        width: 280px;
    }
</style>
