<template>
    <el-dialog :visible.sync="visible" title="运输轨迹">
        <el-row :gutter="15">
            <el-col :span="19">
                <div id="backgroundMap"></div>
                <div class="driver_info">
                    <div class="driver_detail">
                        <img src="@/assets/map/driver.svg" />
                        <div>
                            <div>车牌号：闽C88888</div>
                            <div>司机：王师傅 18888888888</div>
                            <div>调度单号：DD123456</div>
                        </div>
                        <div class="driver_status">运输中</div>
                    </div>
                    <div class="order_detail">
                        <div>调度发车时间：2021-12-8 09:49</div>
                        <div>产废企业：BBB,CCC,DDD...</div>
                        <div>委托订单：EEE,FFF,GGG...</div>
                    </div>
                </div>
            </el-col>
            <el-col :span="5">
                <h3>节点跟踪</h3>
                <div>整车</div>
                <el-timeline>
                    <el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.timestamp">
                        {{ activity.content }}
                    </el-timeline-item>
                </el-timeline>
                <div>
                    <el-button size="small">上一个</el-button>
                    <el-button size="small">下一个</el-button>
                </div>
            </el-col>
        </el-row>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                activities: [
                    {
                        content: "已发车：xx分拣中心",
                        timestamp: "2021-12-8 09:49:10"
                    },
                    {
                        content: "已装运：xx企业",
                        timestamp: "2021-12-8 09:49:10"
                    },
                    {
                        content: "过磅：xx分拣中心",
                        timestamp: "2021-12-8 09:49:10"
                    },
                    {
                        content: "完成运输",
                        timestamp: "2021-12-8 09:49:10"
                    }
                ]
            };
        },
        methods: {
            init() {
                this.visible = true;

                this.$nextTick(() => {
                    this.initMap();
                });
            },
            initMap() {
                const map = new BMap.Map("backgroundMap");
                const point = new BMap.Point(116.404, 39.915);
                map.centerAndZoom(point, 15);
                map.enableScrollWheelZoom(true);
            }
        }
    };
</script>
<style lang="scss" scoped>
    #backgroundMap {
        width: 100%;
        height: 680px;
    }

    .driver_info {
        width: 350px;
        height: 180px;
        border: 1px solid #000;
        border-radius: 6px;
        position: absolute;
        top: 10px;
        left: 20px;
        background-color: #fff;
        .driver_detail {
            height: 95px;
            display: flex;
            justify-content: space-between;
            .driver_status {
            }
            .order_detail {
            }
        }
    }
</style>
