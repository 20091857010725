<template>
    <el-dialog :visible.sync="visible" :title="title" width="500px" @close="close">
        <el-form ref="rejectForm" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="委托单号">{{ form.wOrderId }}</el-form-item>
            <el-form-item label="产废企业">{{ form.unitNa }}</el-form-item>
            <el-form-item :label="isReject ? '拒绝原因' : '取消原因'" prop="reason">
                <el-input v-model="form.reason" type="textarea" :rows="3"></el-input>
            </el-form-item>
        </el-form>
        <div v-if="!isReject">委托订单取消后，调度单{{ scheduleId }}内该委托订单任务同步移除</div>
        <div class="form_handle">
            <el-button type="primary" size="small" @click="save">确定</el-button>
            <el-button size="small" @click="visible = false">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        methods: {
            init(option, isReject) {
                this.visible = true;

                this.isReject = isReject;
                this.form.wOrderId = option.wOrderId;
                this.form.unitNa = option.unitNa;
                this.scheduleId = option.scheduleId;

                if (this.isReject) {
                    this.title = "拒绝委托订单";
                    this.title2 = "拒绝原因";
                } else {
                    this.title = "取消委托订单";
                    this.title2 = "取消原因";
                }
            },
            save() {
                this.$refs.rejectForm.validate(valide => {
                    if (valide) {
                        let url = "/wasteOrder/reject",
                            option = { wOrderId: this.form.wOrderId };
                        if (!this.isReject) {
                            url = "/wasteOrder/cancel";
                            option.cancelRS = this.form.reason;
                        } else {
                            option.rejectRS = this.form.reason;
                        }
                        this.$http.post(url, option).then(res => {
                            this.$message.success({
                                message: res.msg
                            });

                            this.visible = false;
                            this.$emit("update");
                        });
                    }
                });
            },
            close() {
                this.form = {
                    wOrderId: "",
                    reason: "",
                    unitNa: ""
                };
            }
        },
        data() {
            return {
                visible: false,
                isReject: false,

                title: "取消委托订单",
                title2: "取消原因",
                form: {
                    wOrderId: "",
                    reason: ""
                },

                rules: {
                    reason: [{ required: true, message: "原因不能为空", trigger: "blur" }]
                },

                scheduleId: ""
            };
        }
    };
</script>
<style scoped>
    .form_handle {
        text-align: center;
        margin-top: 15px;
    }
</style>
